/**
 * @flow
 */
import { useCallback } from "react"
import _ from "lodash"

import { useMutation } from "@apollo/react-hooks"

import { useStaticQuery, graphql } from "gatsby"
import { CHECKOUT_CREATE, CHECKOUT_LINE_ITEMS_REPLACE } from "./mutations"

const FETCH_PRODUCTS = graphql`
  query getShopifyProducts {
    allShopifyProducts {
      edges {
        node {
          id
          title
          productType
          descriptionHtml
          images {
            edges {
              node {
                id
                src
              }
            }
          }
          variants {
            edges {
              node {
                id
                price
                title
                image {
                  src
                }
                selectedOptions {
                  name
                  value
                }
              }
            }
          }
        }
      }
    }
  }
`

export const useStaticProducts = () => {
  const response = useStaticQuery(FETCH_PRODUCTS)

  const products = []
  const edges = response.allShopifyProducts.edges
  for (let edge of edges) {
    products.push(edge.node)
  }
  return products
}

export const useCheckoutCreate = () => {
  const [createCheckout] = useMutation(CHECKOUT_CREATE)

  const create = useCallback(
    async (variantId: string, quantity?: number) => {
      if (quantity == undefined) {
        quantity = 1
      }

      const errors = []
      let result

      try {
        result = await createCheckout({
          variables: {
            input: {
              lineItems: [
                {
                  variantId,
                  quantity,
                },
              ],
            },
          },
        })

        if (!_.isEmpty(result.data.checkoutCreate.checkoutUserErrors)) {
          for (const error of result.data.createCheckout.checkoutUserErrors) {
            const { message } = error
            errors.push(message)
          }
        }
      } catch (err) {
        console.error(err)
        throw err
      }
      return [result, errors]
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  )
  return create
}

export const useCheckoutLineItemsReplace = () => {
  const [replaceLineItems] = useMutation(CHECKOUT_LINE_ITEMS_REPLACE)

  const replace = useCallback(
    async (
      lineItems: [{ variantId: string, quantity: number }],
      checkoutId: string
    ) => {
      const errors = []
      let result

      try {
        result = await replaceLineItems({
          variables: {
            lineItems,
            checkoutId,
          },
        })

        if (!_.isEmpty(result.data.userErrors)) {
          for (const error of result.data.userErrors) {
            const { message } = error
            errors.push(message)
          }
        }
      } catch (err) {
        console.error(err)
        throw err
      }
      return [result, errors]
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  )
  return replace
}
