import gql from "graphql-tag"
import { CHECKOUT_FRAGMENT } from "./fragments"

export const CUSTOMER_ORDERS_FETCH = gql`
  query getCustomerOrders($customerAccessToken: String!) {
    customer(customerAccessToken: $customerAccessToken) {
      orders(first: 5) {
        edges {
          node {
            id
            customerUrl
            name
            processedAt
            orderNumber
            totalPriceV2 {
              amount
              currencyCode
            }
          }
        }
      }
    }
  }
`

export const CUSTOMER_LAST_INCOMPLETE_CHECKOUT = gql`
  query getCustomerLastIncompleteCheckout($customerAccessToken: String!) {
    customer(customerAccessToken: $customerAccessToken) {
      lastIncompleteCheckout {
        ...CheckoutFragment
      }
    }
  }
  ${CHECKOUT_FRAGMENT}
`

export const GET_CHECKOUT_QUERY = gql`
  query getCheckout($id: ID!) {
    node(id: $id) {
      ...CheckoutFragment
    }
  }
  ${CHECKOUT_FRAGMENT}
`
