import React from "react"
import PropTypes from "prop-types"
import { navigate } from "gatsby"
import styled from "styled-components"

import { Button } from "../elements"
// import { useSignOut } from "../auth/utils"

import Step from "./Step"

import backgroundImg from "../../images/application/texture.png"
import backgroundImgTop from "../../images/landing/top-texture.png"
import callImg from "../../images/application/phone-icon.svg"
import protectedImg from "../../images/application/data-protected-icon.svg"
/**
 *
 */
const StepIndicator = ({ currentStepNumber, mustCollect }) => {
  // const signOut = useSignOut()
  mustCollect = mustCollect || false

  const getSteps = mustCollect => {
    return [
      {
        stepNumber: 1,
        stepText: "Photo Book Type",
        get isComplete() {
          return currentStepNumber > this.stepNumber
        },
        get isCurrent() {
          return currentStepNumber == this.stepNumber
        },
        url: "/",
      },
      {
        stepNumber: 2,
        stepText: "Personal Details",
        get isComplete() {
          return currentStepNumber > this.stepNumber
        },
        get isCurrent() {
          return currentStepNumber == this.stepNumber
        },
      },
      {
        stepNumber: 3,
        stepText: "Delivery Address",
        get isComplete() {
          return currentStepNumber > this.stepNumber
        },
        get isCurrent() {
          return currentStepNumber == this.stepNumber
        },
        url: "/app/delivery",
      },
      {
        stepNumber: 4,
        stepText: mustCollect ? "Collection Address" : "Upload Photos",
        get isComplete() {
          return currentStepNumber > this.stepNumber
        },
        get isCurrent() {
          return currentStepNumber == this.stepNumber
        },
        url: mustCollect ? "/app/collection" : "/app/upload",
      },
      {
        stepNumber: 5,
        stepText: "Payment",
        get isComplete() {
          return currentStepNumber > this.stepNumber
        },
        get isCurrent() {
          return currentStepNumber == this.stepNumber
        },
        url: "/app/payment",
      },
    ]
  }

  const handleCancelOrder = () => {
    // Decide what we'll do here. Clear the checkout maybe
    navigate("/")
  }

  // const handleSignOut = () => {
  //   signOut()
  //   navigate("/")
  // }

  return (
    <Container>
      <div className="mobile-padding">
        <Logo>Build My Book</Logo>
      </div>
      <div className="mobile-padding steps">
        {getSteps(mustCollect).map(step => (
          <Step
            key={step.stepNumber}
            stepNumber={step.stepNumber}
            stepText={step.stepText}
            isComplete={step.isComplete}
            isCurrent={step.isCurrent}
            url={step.url}
          />
        ))}
      </div>
      <CallUs className="mobile-padding">
        <p>Any questions? Give us a call</p>
        <a href="tel:0873650799">
          <img src={callImg} /> 087 365 0799
        </a>
      </CallUs>
      <Protected className="mobile-padding">
        <p>
          <img src={protectedImg} />
          Your data is protected
        </p>
      </Protected>
      <div className="mobile-padding buttons">
        <Button onClick={handleCancelOrder} className="app-btn-grey">
          Cancel Order
        </Button>
        {/* NO SIGN OUT ON DESIGN ? */}
        {/* <Button onClick={handleSignOut}>Log out</Button> */}
      </div>
    </Container>
  )
}

const Container = styled.div`
  padding: 50px 5% 50px 5%;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url(${backgroundImgTop});
  background-position: bottom;
  .mobile-padding {
    padding-bottom: 30px;
  }
  .steps {
    margin: 30px 0 0 0;
    display: flex;
    justify-content: space-between;
  }
  @media (min-width: 768px) {
    background-color: transparent;
    background-image: url(${backgroundImg});
    background-position: right;

    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: 100vh;
    padding: 50px 0 50px 10%;
    .mobile-padding {
      padding-bottom: 0;
    }
    .steps {
      display: block;
    }
  }
  @media (max-width: 600px) {
    .buttons {
      display: none;
    }
  }
`

const Logo = styled.p`
  font-family: ${props => props.theme.font.saturdayRock};
  font-size: 2rem;
`
const CallUs = styled.div`
  p,
  a {
    font-family: ${props => props.theme.font.galanoSemi};
  }
  p {
    font-size: 0.8rem;
    color: ${props => props.theme.color.grey};
    margin-bottom: 5px;
  }
  a {
    color: ${props => props.theme.color.dark};
    transition: 0.25s ease;
    img {
      width: 18px;
      position: relative;
      top: 2px;
      margin-right: 5px;
    }
    &:hover {
      opacity: 0.6;
    }
  }
  @media (max-width: 600px) {
    display: none;
  }
`

const Protected = styled.div`
  p {
    font-family: ${props => props.theme.font.galanoSemi};
    color: ${props => props.theme.color.grey};
    font-size: 0.8rem;
    img {
      width: 18px;
      position: relative;
      top: 4px;
      margin-right: 6px;
    }
  }
  @media (max-width: 600px) {
    display: none;
  }
`
StepIndicator.propTypes = {
  currentStepNumber: PropTypes.number.isRequired,
  mustCollect: PropTypes.bool,
}

export default StepIndicator
