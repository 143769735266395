import React, { useMemo, useState } from "react"
import { navigate } from "gatsby"
import { useDispatch, useSelector } from "react-redux"

import { Logger } from "@aws-amplify/core"
import * as yup from "yup"

import Grid from "@material-ui/core/Grid"
import Checkbox from "@material-ui/core/Checkbox"
import FormControlLabel from "@material-ui/core/FormControlLabel"
import { Field } from "formik"
import styled from "styled-components"

import {
  notify,
  setCollectionAddress,
  setBookAttributes,
} from "../../state/actions"

import { SEO } from "../utils"
import { TextField } from "../elements"
// import { ToggleSwitch } from "../elements"
import StepIndicator from "./StepIndicator"
import {
  useVariantRequiresCollection,
  useCollectionAddressUpdate,
} from "./utils"
import AddressForm from "./AddressForm"
import StepIndicatorMobile from "../elements/StepIndicatorMobile"

const TITLE = "Collection"
const STEP_NUMBER = 4

const bookAttributeValidation = {
  bookTitle: yup.string().required("Required"),
  // isTitleOnSpine: yup.bool().required("Required"),
}

const emptyAddress = {
  address1: "",
  address2: "",
  city: "",
  province: "",
  zip: "",
  country: "",
}

/**
 *
 */
const CollectionPage = () => {
  const logger = useMemo(() => new Logger(CollectionPage.name), [])
  const variantRequiresCollection = useVariantRequiresCollection()
  const dispatch = useDispatch()
  const checkoutId = useSelector(state => state.checkout.id)
  const deliveryAddress = useSelector(state => state.shopify.deliveryAddress)
  const collectionAddress = useSelector(
    state => state.shopify.collectionAddress
  )
  const bookAttributes = useSelector(state => state.shopify.bookAttributes)
  const updateCollectionAddress = useCollectionAddressUpdate(checkoutId)
  const [sameAsDeliveryAddress, setSameAsDeliveryAddress] = useState(false)

  let initialValues

  const onFormSubmit = async (values, actions) => {
    const { bookTitle, ...address } = values
    try {
      await updateCollectionAddress(address, bookTitle)
      dispatch(setCollectionAddress(values))
      dispatch(setBookAttributes({ bookTitle }))
      handleNext()
    } catch (error) {
      logger.error(error)
      dispatch(
        notify({
          message: `Something went wrong, please try again`,
          variant: "error",
        })
      )
    } finally {
      actions.setSubmitting(false)
    }
  }

  const populateValues = () => {
    if (deliveryAddress && sameAsDeliveryAddress) {
      initialValues = {
        ...deliveryAddress,
        ...bookAttributes,
      }
    } else {
      initialValues = {
        ...(collectionAddress ? collectionAddress : emptyAddress),
        ...bookAttributes,
      }
    }
  }

  const handleNext = () => {
    logger.debug("Going to payment guide page")
    navigate("/app/payment")
  }

  const handleCheckSameAsDelivery = event => {
    setSameAsDeliveryAddress(event.target.checked)
  }

  const getBookAttributeInputs = () => {
    return (
      <>
        <Grid item xs={12}>
          <p>Book Attributes</p>
        </Grid>
        <Grid item xs={12}>
          <Field
            component={TextField}
            id="bookTitle"
            label="Front cover title"
            name="bookTitle"
            required
          />
        </Grid>
      </>
    )
  }

  populateValues()
  return (
    <>
      <SEO title={TITLE} />
      <Grid container>
        <Grid item xs={12} sm={4} lg={4}>
          <StepIndicator
            currentStepNumber={STEP_NUMBER}
            mustCollect={variantRequiresCollection}
          />
        </Grid>
        <Grid item xs={12} sm={8} lg={4}>
          <AddressForm
            onSubmit={onFormSubmit}
            initialValues={initialValues || undefined}
            disabled={sameAsDeliveryAddress}
            enableReinitialize
            extraInputs={getBookAttributeInputs()}
            extraValidation={bookAttributeValidation}
            isCollection={true}
          >
            {/* Extra component rendered above submit button */}
            <FormControlContainer
              control={
                <Checkbox
                  value=""
                  checked={sameAsDeliveryAddress}
                  onChange={handleCheckSameAsDelivery}
                />
              }
              label="Same as delivery address"
            />
          </AddressForm>
        </Grid>
      </Grid>
      <StepIndicatorMobile />
    </>
  )
}

const FormControlContainer = styled(FormControlLabel)`
  .MuiButtonBase-root {
    &.Mui-checked {
      color: ${props => props.theme.color.orange};
    }
    &:hover {
      background-color: rgba(229, 188, 113, 0.3);
    }
  }
`
export default CollectionPage
