import PropTypes from "prop-types"
import _ from "lodash"
import { Logger } from "@aws-amplify/core"
import { navigate } from "gatsby"
import React, { useMemo } from "react"
import { useDispatch } from "react-redux"
import styled from "styled-components"

import { notify } from "../../state"
import { SEO } from "../utils"
import FormContainer from "./FormContainer"
import SignUpForm from "./SignUpForm"
import { useSignUp } from "./utils"
import { Link } from "../elements"

const SignUp = ({ autoFocusOnFirstField = false }) => {
  const logger = useMemo(() => new Logger(SignUp.name), [])
  const signUp = useSignUp()
  const dispatch = useDispatch()

  const handleSubmit = async (values, actions) => {
    const { firstName, lastName, email, password } = values

    try {
      let [response, errors] = await signUp(
        firstName,
        lastName,
        email,
        password,
        false //TODO: update from form if necessary
      )
      logger.info(response, errors)

      if (!_.isEmpty(errors)) {
        dispatch(
          notify({
            message: errors.join("\n"),
            variant: "error",
          })
        )
      } else {
        logger.info("Sign in success")
        navigate("/app", { replace: true })
      }
    } catch (err) {
      logger.error(err)
      dispatch(
        notify({
          message: err.message,
          variant: "error",
        })
      )
    } finally {
      actions.setSubmitting(false)
    }
  }

  return (
    <>
      <SEO title="Sign up" />
      <FormContainer>
        <PersonalContainer>
          <h1>Tell us more about yourself</h1>
          <p>
            If you already have a Build My Book account,{" "}
            <Link to="/sign-in" className="sign-in">
              sign in
            </Link>{" "}
            here. <br />
            If you’re new, let’s create your account - just fill in your details
            below.
          </p>
          <SignUpForm
            onSubmit={handleSubmit}
            autoFocusOnFirstField={autoFocusOnFirstField}
          />
        </PersonalContainer>
      </FormContainer>
    </>
  )
}

const PersonalContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 50px 0 50px 0;
  h1 {
    font-size: 1.2rem;
    font-family: ${props => props.theme.font.galanoSemi};
    color: ${props => props.theme.color.dark};
  }
  p {
    font-size: 0.9rem;
    font-family: ${props => props.theme.font.galanoSemi};
    color: ${props => props.theme.color.grey};
    .sign-in,
    span {
      position: relative;
      display: inline-block;
      &:after {
        content: "";
        background: ${props => props.theme.color.grey};
        height: 2px;
        width: 100%;
        position: absolute;
        left: 0;
        bottom: 1px;
      }
    }
    .sign-in {
      font-family: ${props => props.theme.font.galanoSemi};
      transition: 0.25s ease;
      &:hover {
        opacity: 0.6;
      }
    }
  }
  @media (min-width: 768px) {
    min-height: 100vh;
    padding: 100px 5% 50px 5%;
  }
`

SignUp.propTypes = {
  onSignIn: PropTypes.func.isRequired,
  autoFocusOnFirstField: PropTypes.bool,
}

export default SignUp
