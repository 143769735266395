/**
 * @flow
 */
import _ from "lodash"
import { Field, Formik } from "formik"
import React from "react"
import * as yup from "yup"

import { Grid } from "@material-ui/core"

import { Form, SubmitButton, TextField, SelectField } from "../elements"
import styled from "styled-components"

type Values = {
  address1: string,
  address2: string,
  city: string,
  country: string,
  province: string,
  zip: string,
}

type Actions = {
  setSubmitting: Function,
}

type Props = {
  onSubmit: (values: Values, actions: Actions) => void | Promise<mixed>,
  initialValues?: Values,
  extraInputs?: any,
  extraValidation?: {},
  children: any,
  disabled: boolean,
  isCollection: boolean,
}

const AddressForm = ({
  isCollection,
  onSubmit,
  children,
  disabled,
  initialValues = {
    address1: "",
    address2: "",
    city: "",
    province: "",
    zip: "",
    country: "",
  },
  extraInputs,
  extraValidation,
  ...props
}: Props) => {
  if (_.isEmpty(initialValues.country)) {
    initialValues.country = "ZA"
  }
  if (_.isEmpty(extraValidation)) {
    extraValidation = {}
  }

  const provinceOptions = [
    { name: "Eastern Cape", value: "EC" },
    { name: "Free State", value: "FS" },
    { name: "Gauteng", value: "GT" },
    { name: "KwaZulu-Natal", value: "NL" },
    { name: "Limpopo", value: "LP" },
    { name: "Mpumalanga", value: "MP" },
    { name: "North West", value: "NW" },
    { name: "Northern Cape", value: "NC" },
    { name: "Western Cape", value: "WC" },
  ]

  const validationSchema = yup.object().shape({
    address1: yup.string().required("Required"),
    city: yup.string().required("Required"),
    province: yup.string().required("Required"),
    zip: yup.string().required("Required"),
    ...extraValidation,
  })
  return (
    <Formik
      initialValues={initialValues}
      validateOnBlur={true}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
      enableReinitialize={true}
      {...props}
    >
      {({ isSubmitting, errors }) => (
        <Form noValidate>
          <Container>
            <div>
              {isCollection ? (
                <h1>Where should we collect from?</h1>
              ) : (
                <h1>Where should we deliver to?</h1>
              )}
              <Grid container>
                <Grid item xs={12}>
                  <Field
                    autoComplete="address1"
                    component={TextField}
                    id="address1"
                    label="Address"
                    name="address1"
                    disabled={disabled}
                    required
                  />
                </Grid>
                <Grid item xs={12}>
                  <Field
                    autoComplete="address2"
                    component={TextField}
                    id="address2"
                    label="Apartment / House # etc."
                    name="address2"
                    disabled={disabled}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Field
                    autoComplete="city"
                    component={TextField}
                    id="city"
                    label="City"
                    name="city"
                    disabled={disabled}
                    required
                  />
                </Grid>
                <Grid item xs={12}>
                  <Field
                    autoComplete="province"
                    component={SelectField}
                    id="province"
                    label="Province"
                    name="province"
                    options={provinceOptions}
                    disabled={disabled}
                    required
                  />
                </Grid>
                <Grid item xs={12}>
                  <Field
                    autoComplete="zip"
                    component={TextField}
                    id="zip"
                    label="Postal Code"
                    name="zip"
                    disabled={disabled}
                    required
                  />
                </Grid>

                {children}
                {extraInputs}
              </Grid>
            </div>

            <div>
              <SubmitButton
                color="primary"
                variant="contained"
                fullWidth
                loading={isSubmitting}
                disabled={isSubmitting || !_.isEmpty(errors)}
                className="submit"
              >
                NEXT
              </SubmitButton>
            </div>
          </Container>
        </Form>
      )}
    </Formik>
  )
}

const Container = styled.div`
  padding: 50px 5% 50px 5%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  h1 {
    font-size: 1.2rem;
    font-family: ${props => props.theme.font.galanoSemi};
    color: ${props => props.theme.color.dark};
    margin-bottom: 30px;
  }
  @media (min-width: 768px) {
    min-height: 100vh;
    padding: 100px 5% 50px 5%;
  }
`
export default AddressForm
