import PropTypes from "prop-types"
import Auth from "@aws-amplify/auth"
import { Logger } from "@aws-amplify/core"
import { navigate } from "gatsby"
import React, { useMemo } from "react"
import { useDispatch } from "react-redux"

import { Box, Typography } from "@material-ui/core"

import { notify } from "../../state"
import { Copyright, Link } from "../elements"
import { SEO } from "../utils"
import FormContainer from "./FormContainer"
import SignInForm from "./SignInForm"
import { useSignIn } from "./utils"
import styled from "styled-components"

const SignIn = ({ onSignUp, autoFocusOnFirstField = false }) => {
  const logger = useMemo(() => new Logger(SignIn.name), [])
  const signIn = useSignIn()
  const dispatch = useDispatch()

  const handleSubmit = async (values, actions) => {
    const { email, password } = values
    try {
      await signIn(email, password)
      dispatch(
        notify({
          message: "You signed in successfully.",
          variant: "success",
        })
      )
      setTimeout(() => {
        navigate("/app", { replace: true })
      }, 500)
    } catch (err) {
      logger.error(err)
      if (err.code === "UserNotConfirmedException") {
        await Auth.resendSignUp(email)
        navigate("/confirm-sign-up", {
          state: { email, password },
        })
      } else {
        dispatch(
          notify({
            message: err.message,
            variant: "error",
          })
        )
      }
    } finally {
      actions.setSubmitting(false)
    }
  }

  const handleSignUpClick = e => {
    e.preventDefault()
    onSignUp(e)
  }

  return (
    <>
      <SEO title="Sign in" />
      <Container>
        <Logo to="/">Build My Book</Logo>
        <FormContainer>
          <Typography component="h1" variant="h5">
            Sign in
          </Typography>
          <Box marginTop={1} clone>
            <SignInForm
              onSubmit={handleSubmit}
              autoFocusOnFirstField={autoFocusOnFirstField}
            />
          </Box>
          <Options>
            <Link to="/forgot-password">
              Forgot password? <div />
            </Link>
            <Link to="" onClick={handleSignUpClick}>
              Don&apos;t have an account? Sign up <div />
            </Link>
          </Options>
        </FormContainer>
        <Box marginTop={5}>
          <Copyright />
        </Box>
      </Container>
    </>
  )
}
const Container = styled.div`
  display: flex;
  justify-content: space-between;
  min-height: 100vh;
  flex-direction: column;
  padding: 50px 0;
  text-align: center;
`

const Logo = styled(Link)`
  font-family: ${props => props.theme.font.saturdayRock};
  font-size: 2rem;
`

const Options = styled.div`
  width: 100%;
  text-align: left;
  a {
    margin-bottom: 5px;
    display: block;
    font-family: ${props => props.theme.font.galanoMed};
    div {
      transition: 0.25s ease;
      height: 2px;
      width: 0px;
      background: ${props => props.theme.color.orange};
    }
    &:hover {
      div {
        width: 30px;
      }
    }
  }
`
SignIn.propTypes = {
  onSignUp: PropTypes.func.isRequired,
  autoFocusOnFirstField: PropTypes.bool,
}

export default SignIn
