import React from "react"
import styled from "styled-components"

const ProductDetails = product => {
  const { title, price, descriptionHtml } = product
  let roundPrice = Math.round(price)
  return (
    <Container>
      <div className="header">
        <h1>{title}</h1>
        <div
          className="description"
          dangerouslySetInnerHTML={{ __html: descriptionHtml }}
        ></div>
      </div>
      <div className="details">
        <h1>
          R{price ? roundPrice : 0} <span>DESIGN, PRINT & DELIVERY INCL.</span>
        </h1>
      </div>
    </Container>
  )
}

const Container = styled.div`
  .header {
    padding-bottom: 20px;
    h1 {
      font-size: 2rem;
      font-family: ${props => props.theme.font.galanoBold};
      color: ${props => props.theme.color.dark};
    }
    p {
      font-size: 1rem;
      font-family: ${props => props.theme.font.galanoMed};
    }
  }
  .details {
    h1 {
      font-size: 2rem;
      font-family: ${props => props.theme.font.galanoBold};
      color: ${props => props.theme.color.orange};
      margin-bottom: 5px;
      span {
        font-size: 0.6rem;
        color: ${props => props.theme.color.dark};
        position: relative;
        left: -5px;
      }
    }
    .list {
      p {
        font-size: 0.6rem;
        font-family: ${props => props.theme.font.galanoBold};
        color: ${props => props.theme.color.grey};
        line-height: 20px;
      }
    }
  }
  @media (min-width: 768px) {
    .header {
      h1 {
        font-size: 2.6rem;
      }
      p {
        font-size: 1rem;
      }
    }
    .details {
      h1 {
        font-size: 2.6rem;
        span {
          font-size: 0.7rem;
        }
      }
      .list {
        p {
          font-size: 0.7rem;
        }
      }
    }
  }
`
export default ProductDetails
