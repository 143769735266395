/**
 * @flow
 */

import React from "react"
import type { Node } from "react"

import Box from "@material-ui/core/Box"
import makeStyles from "@material-ui/core/styles/makeStyles"

const useStyles = makeStyles(() => ({
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "0 5%",
  },
}))

type Props = {
  children?: Node,
}

const FormContainer = ({ children, ...props }: Props) => {
  const classes = useStyles()
  return (
    <Box className={classes.root} {...props}>
      {children}
    </Box>
  )
}

export default FormContainer
