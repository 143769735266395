import React, { useMemo } from "react"
import * as yup from "yup"
import { navigate } from "gatsby"
import { Logger } from "@aws-amplify/core"
import Grid from "@material-ui/core/Grid"
import { Field, Formik } from "formik"

import { useSelector, useDispatch } from "react-redux"

import { notify, setBookAttributes } from "../../state/actions"
import { Gallery, Form, TextField } from "../elements"
// import { ToggleSwitch } from "../elements"
import { SEO } from "../utils"
import StepIndicator from "./StepIndicator"
import { useCustomAttrUpdate } from "./utils"
import styled from "styled-components"
import StepIndicatorMobile from "../elements/StepIndicatorMobile"

const TITLE = "Upload"
const STEP_NUMBER = 4

const validationSchema = yup.object().shape({
  bookTitle: yup.string().required("Required"),
  eventType: yup.string().required("Required"),
  // isTitleOnSpine: yup.bool().required("Required"),
})

/**
 *
 */
const UploadPage = () => {
  const logger = useMemo(() => new Logger(UploadPage.name), [])
  const dispatch = useDispatch()
  const checkoutId = useSelector(state => state.checkout.id)
  const updateCustomAttrs = useCustomAttrUpdate(checkoutId)
  const projectId = useSelector(state => state.shopify.projectId)
  const bookAttributes = useSelector(state => state.shopify.bookAttributes)

  const imagePath = `projectId=${projectId}`

  const handleSubmit = async values => {
    try {
      await updateCustomAttrs(projectId, values.bookTitle, values.eventType)
      dispatch(setBookAttributes(values))
      navigate("/app/payment")
    } catch (error) {
      logger.error(error)
      dispatch(
        notify({
          message: `Something went wrong, please try again`,
          variant: "error",
        })
      )
    }
  }

  return (
    <>
      <SEO title={TITLE} />
      <Grid container>
        <Grid item xs={12} sm={4} lg={4}>
          <StepIndicator currentStepNumber={STEP_NUMBER} mustCollect={false} />
        </Grid>
        <Grid item xs={12} sm={8} lg={4}>
          <UploadContainer>
            <div className="header">
              <h1>Upload your photos</h1>
              <p>
                Upload between {process.env.MIN_PHOTO_UPLOADS} and&nbsp;
                {process.env.MAX_PHOTO_UPLOADS} of your favourite photos, and
                we’ll do the rest. For the best possible results, ensure that
                your photos are high resolution (300 dpi). <br />
                <br />
                For best results, please follow the steps below:
                <br />
                1. Please name your photos (1, 2, 3 etc…) in the order you would
                like to have them displayed in your photo book.
                <br />
                2. Please rename the photo you would like to be displayed on the
                book cover “cover”.
                <br />
                <br />
                Please note: No proof designs will be provided. Direct contact
                with our designers are not permitted.
              </p>
              <a href="tel:0873650799">Need help? Call us 087 365 0799</a>
            </div>
            <Formik
              initialValues={bookAttributes}
              validateOnBlur={false}
              validationSchema={validationSchema}
              onSubmit={handleSubmit}
            >
              {({ handleSubmit }) => (
                <>
                  <Form noValidate>
                    <Field
                      autoFocus={true}
                      component={TextField}
                      id="bookTitle"
                      label="Front cover title"
                      name="bookTitle"
                      required
                    />
                    <Field
                      autoFocus={true}
                      component={TextField}
                      id="eventType"
                      label="Tell us more about your Event"
                      name="eventType"
                      required
                    />
                  </Form>
                  {projectId != null && (
                    <Gallery
                      imagePath={imagePath}
                      onContinue={handleSubmit}
                      minPhotoCount={parseInt(process.env.MIN_PHOTO_UPLOADS)}
                      maxPhotoCount={parseInt(process.env.MAX_PHOTO_UPLOADS)}
                    ></Gallery>
                  )}
                </>
              )}
            </Formik>
          </UploadContainer>
        </Grid>
      </Grid>
      <StepIndicatorMobile />
    </>
  )
}

const UploadContainer = styled.div`
  padding: 50px 5% 50px 5%;
  .header {
    h1 {
      font-size: 1.2rem;
      font-family: ${props => props.theme.font.galanoSemi};
      color: ${props => props.theme.color.dark};
    }
    p {
      font-size: 0.9rem;
      font-family: ${props => props.theme.font.galanoSemi};
      color: ${props => props.theme.color.grey};
      padding: 30px 0 10px 0;
    }
    a {
      font-family: ${props => props.theme.font.galanoSemi};
      font-size: 0.8rem;
      &:hover {
        opacity: 0.5;
      }
    }
  }
  @media (min-width: 768px) {
    padding: 100px 5% 50px 5%;
  }
  .MuiFormControl-root {
    margin-bottom: 10px;
  }
`

export default UploadPage
