import React, { useState, useEffect, useCallback } from "react"
import PropTypes from "prop-types"

import Chip from "@material-ui/core/Chip"
import Tooltip from "@material-ui/core/Tooltip"
import styled from "styled-components"
import toolTipImg from "../../images/landing/tooltip-icon.svg"
import ChipIcon from "../elements/ChipIcon"

const Options = ({ options, headings, tooltips, onSelect }) => {
  const [selectedOptions, setSelectedOptions] = useState({})

  useEffect(() => {
    for (const [key, value] of Object.entries(options)) {
      handleOptionSelect(key, value.values().next().value)
    }
  }, [handleOptionSelect, options])

  const handleOptionSelect = useCallback((option, value) => {
    setSelectedOptions(selectedOptions => {
      return { ...selectedOptions, [option]: value }
    })

    if (onSelect) {
      onSelect(option, value)
    }
  })

  const isOptionSelected = (option, value) => {
    return selectedOptions[option] == value
  }

  const getOptionHeading = option => {
    if (headings) {
      return headings[option] || option
    }
    return option
  }

  const getOptionTooltip = option => {
    if (tooltips) {
      return tooltips[option] || option
    }
    return option
  }

  return (
    <>
      {options &&
        Object.keys(options).map(option => {
          return (
            <Container
              key={option}
              className={option == "Upload-or-Collection" ? "hidden" : ""}
            >
              <div className="flex">
                <p>{getOptionHeading(option)}</p>
                <Tooltip title={getOptionTooltip(option)}>
                  <img src={toolTipImg} />
                </Tooltip>
              </div>
              <OptionButtons>
                {Array.from(options[option]).map(value => {
                  return (
                    <Chip
                      icon={
                        <ChipIcon
                          value={value}
                          selected={isOptionSelected(option, value)}
                        />
                      }
                      key={value}
                      label={value}
                      onClick={() => handleOptionSelect(option, value)}
                      className={
                        isOptionSelected(option, value) ? "selected" : "default"
                      }
                    />
                  )
                })}
              </OptionButtons>
            </Container>
          )
        })}
    </>
  )
}

const Container = styled.div`
  &.hidden {
    display: none;
  }
  p {
    font-size: 0.7rem;
    text-transform: uppercase;
    font-family: ${props => props.theme.font.galanoBold};
  }
  .flex {
    display: flex;
    align-items: center;
    img {
      width: 12px;
      margin-left: 5px;
    }
  }
`

const OptionButtons = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 20px;
  margin: 5px 0 15px 0;
  .MuiChip-root {
    position: relative;
    background: ${props => props.theme.color.white};
    padding: 18px 0;
    color: ${props => props.theme.color.dark};
    font-family: ${props => props.theme.font.galanoSemi};
    border-radius: 10px;
    border: 2px solid ${props => props.theme.color.dark};
    text-transform: uppercase;
    font-size: 0.8rem;
    transition: all 0.25s ease;
    &.selected {
      background: ${props => props.theme.color.dark};
      color: ${props => props.theme.color.white};
    }
  }
  @media (min-width: 1024px) {
    width: 330px;
  }
`

Options.propTypes = {
  options: PropTypes.object.isRequired,
  headings: PropTypes.object,
  tooltips: PropTypes.object,
  onSelect: PropTypes.func,
}

export default Options
