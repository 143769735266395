import React, { useEffect, useRef, useState } from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import { Swiper, EffectFade } from "swiper/dist/js/swiper.esm"
import arrowImg from "../../../images/landing/arrow-right-light.svg"

Swiper.use([EffectFade])

const ProductSwiper = props => {
  const swiperElement = useRef(null)
  const swiper = useRef(null)
  const [activeSlide, setActiveSlide] = useState(0)
  const [totalSlides, setTotalSlides] = useState("")

  useEffect(() => {
    if (props.selectedVariant) {
      let variantImg = props.selectedVariant.image.src
      props.product.map((slide, i) => {
        let slideImg = slide.node.src
        if (slideImg === variantImg) {
          if (swiper.current) {
            swiper.current.slideTo(i)
          }
        }
      })
    }
  }, [props.selectedVariant, props.product])

  useEffect(() => {
    if (props.product) {
      setTotalSlides(props.product.length)
    }
    swiper.current = new Swiper(swiperElement.current, {
      effect: "fade",
      fadeEffect: {
        crossFade: false,
      },
      slidesPerView: 1,
      initialSlide: activeSlide,
      on: {
        transitionStart: () => {
          if (swiper.current) {
            setActiveSlide(swiper.current.realIndex)
          }
        },
      },
    })
  }, []) // eslint-disable-line

  const slideNext = () => {
    if (swiper.current) {
      if (activeSlide + 1 == totalSlides) {
        swiper.current.slideTo(0)
      } else {
        swiper.current.slideNext()
      }
    }
  }

  const renderProductImages = () => {
    return props.product.map((image, i) => {
      return (
        <div className="swiper-slide" key={i}>
          <img key={image.node.id} src={image.node.src} alt="Build My Book" />
        </div>
      )
    })
  }

  const renderSlideIndex = () => {
    return (
      <SlideIndex>
        <p>
          {activeSlide + 1 <= 9 && 0}
          {activeSlide + 1} &#x2014; {totalSlides <= 9 && 0}
          {totalSlides}
        </p>
      </SlideIndex>
    )
  }

  const renderSlideNavigation = () => {
    return (
      <>
        <ArrowRight onClick={slideNext}>
          <img src={arrowImg} alt="Build My Book" />
        </ArrowRight>
        <SlideNumber viewBox="0 0 300 300">
          <text y={300}>
            {activeSlide + 1 <= 9 && 0}
            {activeSlide + 1}
          </text>
        </SlideNumber>
      </>
    )
  }
  return (
    <Container>
      {renderSlideNavigation()}
      {renderSlideIndex()}
      <div className="swiper-container" ref={swiperElement}>
        <div className="swiper-wrapper">{renderProductImages()}</div>
      </div>
    </Container>
  )
}

const Container = styled.div`
  padding-left: 30px;
  position: relative;
  max-width: 90vw;
  .swiper-slide {
    img {
      width: 100%;
      max-height: 80vh;
      object-fit: cover;
      object-position: center center;
    }
  }
  @media (max-width: 1100px) {
    .swiper-slide {
      opacity: 0 !important;
      transform: 0.25s ease;
      &.swiper-slide-active {
        opacity: 1 !important;
      }
    }
  }
`
const SlideIndex = styled.div`
  position: absolute;
  left: 0;
  p {
    writing-mode: vertical-rl;
    -webkit-writing-mode: vertical-rl;
    transform: scale(-1);
    font-family: ${props => props.theme.font.galanoSemi};
    color: ${props => props.theme.color.lightGrey};
  }
`
const SlideNumber = styled.svg`
  width: 250px;
  max-width: 100%;
  position: absolute;
  top: -200px;
  left: 30px;
  text {
    font-family: ${props => props.theme.font.butler};
    font-size: 180px;
    stroke: ${props => props.theme.color.lightGrey};
    stroke-width: 2;
    fill: transparent;
  }
  @media (min-width: 768px) {
    width: 300px;
    top: -250px;
    left: 75px;
    text {
      font-size: 200px;
    }
  }
  @media (min-width: 1024px) {
    text {
      font-size: 250px;
    }
  }
`

const ArrowRight = styled.div`
  position: absolute;
  right: 30px;
  top: -35px;
  z-index: 2;
  cursor: pointer;
  img {
    width: 25px;
  }
  @media (min-width: 768px) {
    img {
      width: 50px;
    }
  }
  @media (min-width: 1024px) {
    top: -70px;
    img {
      width: 100px;
    }
  }
`
ProductSwiper.propTypes = {
  product: PropTypes.array,
  selectedVariant: PropTypes.object,
}

export default ProductSwiper
