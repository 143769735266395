/**
 * @flow
 */

import Auth from "@aws-amplify/auth"
import { Logger } from "@aws-amplify/core"
import { navigate } from "gatsby"
import React, { useMemo } from "react"
import { useDispatch } from "react-redux"

import { Box, Container, Typography } from "@material-ui/core"

import { notify } from "../../state"
import { Copyright, Link } from "../elements"
import { SEO } from "../utils"
import FormContainer from "./FormContainer"
import ResetPasswordForm from "./ResetPasswordForm"
import styled from "styled-components"

type Props = {
  location: Object,
}

const ResetPasswordPage = ({ location }: Props) => {
  const logger = useMemo(() => new Logger(ResetPasswordPage.name), [])
  const dispatch = useDispatch()

  const handleSubmit = async (values, actions) => {
    const { code, password } = values
    const { email } = location.state
    try {
      await Auth.forgotPasswordSubmit(email, code, password)
      dispatch(
        notify({
          message: "Your password was reset successfully! You may now sign in.",
          variant: "success",
        })
      )
      navigate("/sign-in", { replace: true })
    } catch (err) {
      logger.error(err)
      dispatch(
        notify({
          message: err.message,
          variant: "error",
        })
      )
    } finally {
      actions.setSubmitting(false)
    }
  }

  return (
    <>
      <SEO title="Reset password" />
      <Container component="main" maxWidth="xs">
        <ResetPasswordContainer>
          <Logo to="/">Build My Book</Logo>
          <FormContainer>
            <Typography component="h1" variant="h5" className="header">
              Reset password
            </Typography>
            <Box mt={1} clone>
              <ResetPasswordForm onSubmit={handleSubmit} />
            </Box>
            <Options>
              <Link to="/forgot-password">
                Didn&apos;t get the confirmation code? Try again <div />
              </Link>
            </Options>
          </FormContainer>
          <Box mt={5}>
            <Copyright />
          </Box>
        </ResetPasswordContainer>
      </Container>
    </>
  )
}

const ResetPasswordContainer = styled.div`
  display: flex;
  justify-content: space-between;
  min-height: 100vh;
  flex-direction: column;
  padding: 50px 0;
  text-align: center;
  .header {
    margin-bottom: 20px;
  }
`
const Logo = styled(Link)`
  font-family: ${props => props.theme.font.saturdayRock};
  font-size: 2rem;
`
const Options = styled.div`
  width: 100%;
  text-align: left;
  a {
    margin-top: 20px;
    display: block;
    font-family: ${props => props.theme.font.galanoMed};
    div {
      transition: 0.25s ease;
      height: 2px;
      width: 0px;
      background: ${props => props.theme.color.orange};
    }
    &:hover {
      div {
        width: 30px;
      }
    }
  }
`
export default ResetPasswordPage
