/**
 * @flow
 */
import React from "react"
import { Container } from "@material-ui/core"
import { navigate } from "gatsby"

import SignUp from "./SignUp"

const SignUpPage = () => {
  const handleSignIn = () => {
    navigate("/sign-in")
  }

  return (
    <Container component="main" maxWidth="xs">
      <SignUp onSignIn={handleSignIn} autoFocusOnFirstField={true} />
    </Container>
  )
}

export default SignUpPage
