import { Logger } from "@aws-amplify/core"
import { PhoneNumberFormat, PhoneNumberUtil } from "google-libphonenumber"
import _ from "lodash"

const phoneUtil = PhoneNumberUtil.getInstance()
const logger = new Logger("phonenumber")

export const toNationalPhoneNumber = (phoneNumber, regionCode = "ZA") => {
  if (_.isEmpty(phoneNumber)) {
    return null
  }

  let nationalPhoneNumber = phoneNumber
  try {
    const number = phoneUtil.parseAndKeepRawInput(phoneNumber, regionCode)
    nationalPhoneNumber = phoneUtil.format(number, PhoneNumberFormat.NATIONAL)
  } catch (err) {
    logger.error(err)
  }
  return nationalPhoneNumber
}

export const toInternationalPhoneNumber = (phoneNumber, regionCode = "ZA") => {
  if (_.isEmpty(phoneNumber)) {
    return null
  }

  let nationalPhoneNumber = phoneNumber
  try {
    const number = phoneUtil.parseAndKeepRawInput(phoneNumber, regionCode)
    nationalPhoneNumber = phoneUtil.format(number, PhoneNumberFormat.E164)
  } catch (err) {
    logger.error(err)
  }
  return nationalPhoneNumber
}

export const validatePhoneNumber = (phoneNumber, regionCode = "ZA") => {
  if (_.isEmpty(phoneNumber)) {
    return false
  }

  try {
    const number = phoneUtil.parseAndKeepRawInput(phoneNumber, regionCode)
    return phoneUtil.isValidNumber(number)
  } catch (err) {
    logger.error(err)
    return false
  }
}
