import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import Chip from "@material-ui/core/Chip"
import { Link } from "react-scroll"

const ProductSelectButton = ({ products, handleProductChange, active }) => {
  return (
    <Container>
      {products.map((product, i) => {
        return (
          <Link
            to="product"
            spy={true}
            smooth={true}
            offset={150}
            duration={500}
            key={i}
          >
            <Chip
              label={product.title}
              clickable={true}
              onClick={() => handleProductChange(i)}
              className={product.id === active.id ? "selected" : null}
            />
          </Link>
        )
      })}
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .MuiChip-label {
    z-index: 99999;
  }
  .MuiChip-root {
    position: relative;
    background: ${props => props.theme.color.white};
    padding: 18px 0;
    color: ${props => props.theme.color.dark};
    font-family: ${props => props.theme.font.galanoSemi};
    border-radius: 10px;
    border: 2px solid ${props => props.theme.color.dark};
    text-transform: uppercase;
    font-size: 0.8rem;
    transition: all 0.25s ease;
    margin: 10px;
    min-width: 300px;
    &.selected {
      background: ${props => props.theme.color.dark};
      color: ${props => props.theme.color.white};
    }
  }
  @media (min-width: 768px) {
    flex-direction: row;
  }
`

ProductSelectButton.propTypes = {
  products: PropTypes.array,
  handleProductChange: PropTypes.func,
  active: PropTypes.object,
}
export default ProductSelectButton
