/**
 * @flow
 */

import { Logger } from "@aws-amplify/core"
import { navigate } from "gatsby"
import React, { useMemo } from "react"
import { useDispatch } from "react-redux"

import { Box, Container, Typography } from "@material-ui/core"

import { notify } from "../../state"
import { Copyright, Link } from "../elements"
import { SEO } from "../utils"
import ForgotPasswordForm from "./ForgotPasswordForm"
import FormContainer from "./FormContainer"
import styled from "styled-components"
import { useRecoverPassword } from "./utils"

const ForgotPasswordPage = () => {
  const logger = useMemo(() => new Logger(ForgotPasswordPage.name), [])
  const dispatch = useDispatch()
  const recoverPassword = useRecoverPassword()
  const handleSubmit = async (values, actions) => {
    const { email } = values
    try {
      await recoverPassword(email)
      navigate("/sign-in")
      dispatch(
        notify({
          message: "An email has been sent to you.",
          variant: "success",
        })
      )
    } catch (err) {
      logger.error(err)
      dispatch(
        notify({
          message: err.message,
          variant: "error",
        })
      )
    } finally {
      actions.setSubmitting(false)
    }
  }

  return (
    <Container component="main" maxWidth="xs">
      <SEO title="Forgot password" />
      <ForgotPasswordContainer>
        <Logo to="/">Build My Book</Logo>
        <FormContainer>
          <div className="header">
            <Typography component="h1" variant="h5">
              Forgot password
            </Typography>
            <Typography variant="subtitle2">
              Send password reset instructions
            </Typography>
          </div>
          <Box mt={1} clone>
            <ForgotPasswordForm onSubmit={handleSubmit} />
          </Box>
          <Options>
            <Link to="/sign-in">
              Remember password? Sign in <div />
            </Link>
          </Options>
        </FormContainer>
        <Box mt={5}>
          <Copyright />
        </Box>
      </ForgotPasswordContainer>
    </Container>
  )
}

const ForgotPasswordContainer = styled.div`
  display: flex;
  justify-content: space-between;
  min-height: 100vh;
  flex-direction: column;
  padding: 50px 0;
  text-align: center;
  .header {
    margin-bottom: 20px;
  }
`
const Logo = styled(Link)`
  font-family: ${props => props.theme.font.saturdayRock};
  font-size: 2rem;
`
const Options = styled.div`
  width: 100%;
  text-align: left;
  a {
    margin-bottom: 5px;
    display: block;
    font-family: ${props => props.theme.font.galanoMed};
    div {
      transition: 0.25s ease;
      height: 2px;
      width: 0px;
      background: ${props => props.theme.color.orange};
    }
    &:hover {
      div {
        width: 30px;
      }
    }
  }
`
export default ForgotPasswordPage
