import gql from "graphql-tag"

export const CHECKOUT_CREATE = gql`
  mutation checkoutCreate($input: CheckoutCreateInput!) {
    checkoutCreate(input: $input) {
      checkout {
        id
        webUrl
        lineItems(first: 1) {
          edges {
            node {
              title
              quantity
            }
          }
        }
      }
    }
  }
`

export const CHECKOUT_LINE_ITEMS_REPLACE = gql`
  mutation checkoutLineItemsReplace(
    $lineItems: [CheckoutLineItemInput!]!
    $checkoutId: ID!
  ) {
    checkoutLineItemsReplace(lineItems: $lineItems, checkoutId: $checkoutId) {
      checkout {
        id
        lineItems(first: 1) {
          edges {
            node {
              id
              title
              quantity
            }
          }
        }
      }
    }
  }
`
