import { navigate } from "gatsby"
import PropTypes from "prop-types"
import React, { useEffect } from "react"
import { connect } from "react-redux"

import { notify } from "../../state"

const PrivateRoute = ({
  component: Component,
  unauthorized: Unauthorized,
  dispatch,
  user,
  admin,
  ...props
}) => {
  useEffect(() => {
    ;(async () => {
      if (!user) {
        dispatch(
          notify({
            message: "You need to sign in before continuing!",
            variant: "warning",
          })
        )
        navigate("/sign-in", { replace: true })
      }
    })()
  }, [dispatch, user])

  if (user) {
    if (admin && !user.admin) {
      return <Unauthorized user={user} {...props} />
    }
    return <Component user={user} {...props} />
  }

  return null
}

PrivateRoute.propTypes = {
  component: PropTypes.any.isRequired,
  unauthorized: PropTypes.any,
  dispatch: PropTypes.func.isRequired,
  user: PropTypes.object,
  admin: PropTypes.bool,
}

const mapStateToProps = state => ({
  user: state.auth.user,
})

export default connect(mapStateToProps)(PrivateRoute)
