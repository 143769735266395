import _ from "lodash"
import React, { useEffect, useState, useMemo } from "react"
import { useDispatch } from "react-redux"
import { navigate } from "gatsby"

import Auth from "@aws-amplify/auth"
import { Logger } from "@aws-amplify/core"

import CircularProgress from "@material-ui/core/CircularProgress"
import Grid from "@material-ui/core/Grid"

import { notify, setUser } from "../state"

import { SignIn, SignUp } from "./auth"
import { useCustomerDetails } from "./auth/utils"
import { SEO } from "./utils"
import { StepIndicator } from "./checkout"
import { useVariantRequiresCollection } from "./checkout/utils"
import StepIndicatorMobile from "./elements/StepIndicatorMobile"

const TITLE = "Account"

/**
 * Render a sign in or sign up component
 */
const AccountPage = () => {
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(true)
  const [viewSignUp, setViewSignUp] = useState(true)
  const [federatedIdentity, setFederatedIdentity] = useState(null)
  const [customer, isCustomerLoading] = useCustomerDetails()
  const variantRequiresCollection = useVariantRequiresCollection()
  const logger = useMemo(() => new Logger(AccountPage.name), [])

  useEffect(() => {
    ;(async () => {
      try {
        const identity = await Auth.currentAuthenticatedUser()
        setFederatedIdentity(identity)
        logger.info("User already logged in, federated identity: ", identity)
        // eslint-disable-next-line no-empty
      } catch {}
    })()
  }, [logger])

  useEffect(() => {
    ;(async () => {
      try {
        if (!isCustomerLoading && !_.isEmpty(customer) && federatedIdentity) {
          dispatch(setUser(customer))
          navigate("/app", { replace: true })
          dispatch(
            notify({
              message: `Welcome back ${customer.displayName}.`,
              variant: "info",
            })
          )
        }
      } catch (err) {
        // No authenticated user
      } finally {
        setLoading(false)
      }
    })()
  }, [logger, dispatch, customer, isCustomerLoading, federatedIdentity])

  if (loading) return null

  const handleViewSignIn = () => {
    setViewSignUp(false)
  }

  const handleViewSignUp = () => {
    setViewSignUp(true)
  }

  const renderContent = () => {
    if (loading) return <CircularProgress />

    if (viewSignUp) {
      return <SignUp onSignIn={handleViewSignIn} />
    } else {
      return <SignIn onSignUp={handleViewSignUp} />
    }
  }

  return (
    <>
      <SEO title={TITLE} />
      <Grid container>
        <Grid item xs={12} sm={4} lg={4}>
          <StepIndicator
            currentStepNumber={2}
            mustCollect={variantRequiresCollection}
          />
        </Grid>
        <Grid item xs={12} sm={8} lg={4}>
          {renderContent()}
        </Grid>
      </Grid>
      <StepIndicatorMobile />
    </>
  )
}

export default AccountPage
