import graphql from "graphql-tag"

export const CHECKOUT_FRAGMENT = graphql`
  fragment CheckoutFragment on Checkout {
    id
    completedAt
    webUrl
    lineItems(first: 1) {
      edges {
        node {
          id
          quantity
          title
          variant {
            id
            title
            selectedOptions {
              name
              value
            }
          }
        }
      }
    }
    shippingAddress {
      address1
      address2
      city
      countryCodeV2
      provinceCode
      zip
    }
    customAttributes {
      key
      value
    }
  }
`
