import React, { useState } from "react"
import PropTypes from "prop-types"

import Product from "./Product"
import { useStaticProducts } from "./utils"

const ProductSelect = ({ onSelect }) => {
  const products = useStaticProducts()
  const [activeProduct, setActiveProduct] = useState(products[0])

  const handleActiveProduct = i => {
    setActiveProduct(products[i])
  }

  return (
    <Product
      key={activeProduct.id}
      product={activeProduct}
      onSelect={onSelect}
      changeProduct={handleActiveProduct}
      allProducts={products}
    />
  )
}

ProductSelect.propTypes = {
  onSelect: PropTypes.func,
}

export default ProductSelect
