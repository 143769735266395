import _ from "lodash"
import React, { useEffect, useMemo } from "react"
import { useSelector } from "react-redux"
import { navigate } from "gatsby"
import moment from "moment"

import { Logger } from "@aws-amplify/core"
import Paper from "@material-ui/core/Paper"
import Table from "@material-ui/core/Table"
import TableHead from "@material-ui/core/TableHead"
import TableRow from "@material-ui/core/TableRow"
import TableCell from "@material-ui/core/TableCell"
import TableBody from "@material-ui/core/TableBody"
import { makeStyles } from "@material-ui/core/styles"

import { SEO } from "../utils"

const TITLE = "Orders"

const useStyles = makeStyles(theme => ({
  root: {
    marginTop: theme.spacing(3),
    overflowX: "auto",
  },
  table: {
    minWidth: 650,
  },
}))
/**
 *
 */
const OrdersPage = () => {
  const nextUrl = "app/delivery"
  const classes = useStyles()
  const orders = useSelector(state => state.shopify.orders)
  const checkoutId = useSelector(state => state.checkout.id)
  const logger = useMemo(() => new Logger(OrdersPage.name), [])

  useEffect(() => {
    logger.info("Found orders:", orders)
    if (_.isEmpty(orders) && !_.isEmpty(checkoutId)) {
      logger.info("There are no orders yet, but have a checkout id")
      navigate(nextUrl)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orders, checkoutId])

  return (
    <>
      <SEO title={TITLE} />
      <Paper className={classes.root}>
        <h1>Orders</h1>
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              <TableCell>Order</TableCell>
              <TableCell align="right">Date</TableCell>
              <TableCell align="right">Total Price</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {orders.map(order => (
              <TableRow key={order.node.id}>
                <TableCell component="th" scope="row">
                  <a href={order.node.customerUrl}>{order.node.name}</a>
                </TableCell>
                <TableCell align="right">
                  {moment(order.node.processedAt).format("DD MMMM YYYY")}
                </TableCell>
                <TableCell align="right">
                  {order.node.totalPriceV2.amount}
                </TableCell>
                <TableCell align="right">
                  <a href={order.node.customerUrl}>View Details</a>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Paper>
    </>
  )
}

export default OrdersPage
