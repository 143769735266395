import React, { useMemo } from "react"
import { useSelector, useDispatch } from "react-redux"
import { navigate } from "gatsby"

import { Logger } from "@aws-amplify/core"

import Grid from "@material-ui/core/Grid"

import { notify, setDeliveryAddress } from "../../state/actions"
import { SEO } from "../utils"
import StepIndicator from "./StepIndicator"
import { useVariantRequiresCollection, useShippingAddressUpdate } from "./utils"
import AddressForm from "./AddressForm"
import StepIndicatorMobile from "../elements/StepIndicatorMobile"

const TITLE = "Delivery"
const STEP_NUMBER = 3

/**
 *
 */
const DeliveryPage = () => {
  const logger = useMemo(() => new Logger(DeliveryPage.name), [])
  const variantRequiresCollection = useVariantRequiresCollection()
  const dispatch = useDispatch()
  const checkoutId = useSelector(state => state.checkout.id)
  const loggedInUser = useSelector(state => state.auth.user)
  const initialValues = useSelector(state => state.shopify.deliveryAddress)
  const updateShippingAddress = useShippingAddressUpdate(checkoutId)

  const onFormSubmit = async (values, actions) => {
    try {
      const address = {
        ...values,
        firstName: loggedInUser.firstName,
        lastName: loggedInUser.lastName,
      }
      await updateShippingAddress(address)
      dispatch(setDeliveryAddress(address))
      handleNext()
    } catch (error) {
      logger.error(error)
      dispatch(
        notify({
          message: `Something went wrong, please try again`,
          variant: "error",
        })
      )
    } finally {
      actions.setSubmitting(false)
    }
  }

  const handleNext = () => {
    if (variantRequiresCollection) {
      logger.debug("Collection required, need to capture collection address")
      navigate("/app/collection")
    } else {
      logger.debug("Photos will be uploaded, go to upload")
      navigate("/app/upload")
    }
  }

  return (
    <>
      <SEO title={TITLE} />
      <Grid container>
        <Grid item xs={12} sm={4} lg={4}>
          <StepIndicator
            currentStepNumber={STEP_NUMBER}
            mustCollect={variantRequiresCollection}
          />
        </Grid>
        <Grid item xs={12} sm={8} lg={4}>
          <AddressForm
            onSubmit={onFormSubmit}
            initialValues={initialValues || undefined}
          ></AddressForm>
        </Grid>
      </Grid>
      <StepIndicatorMobile />
    </>
  )
}

export default DeliveryPage
