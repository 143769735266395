import React from "react"
import styled from "styled-components"
import { Button } from "../elements"
import { navigate } from "gatsby"

import callImg from "../../images/application/phone-icon.svg"
import protectedImg from "../../images/application/data-protected-icon.svg"
import bottomBackgroundImage from "../../images/landing/footer-texture.png"

const StepIndicatorMobile = () => {
  const handleCancelOrder = () => {
    // Decide what we'll do here. Clear the checkout maybe
    navigate("/")
  }
  return (
    <Container>
      <CallUs className="item">
        <p>Any questions? Give us a call</p>
        <a href="tel:0873650799">
          <img src={callImg} /> 087 365 0799
        </a>
      </CallUs>
      <Protected className="item">
        <p>
          <img src={protectedImg} />
          Your data is protected
        </p>
      </Protected>
      <Button onClick={handleCancelOrder} className="app-btn-grey">
        Cancel Order
      </Button>
    </Container>
  )
}

const Container = styled.div`
  background-image: url(${bottomBackgroundImage});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
  padding: 50px 25px 25px 25px;
  text-align: center;
  .item {
    margin-bottom: 20px;
  }
  @media (min-width: 600px) {
    display: none;
  }
`

const CallUs = styled.div`
  p,
  a {
    font-family: ${props => props.theme.font.galanoSemi};
  }
  p {
    font-size: 0.8rem;
    color: ${props => props.theme.color.grey};
    margin-bottom: 5px;
  }
  a {
    color: ${props => props.theme.color.dark};
    transition: 0.25s ease;
    img {
      width: 18px;
      position: relative;
      top: 2px;
      margin-right: 5px;
    }
    &:hover {
      opacity: 0.6;
    }
  }
`
const Protected = styled.div`
  p {
    font-family: ${props => props.theme.font.galanoSemi};
    color: ${props => props.theme.color.grey};
    font-size: 0.8rem;
    img {
      width: 18px;
      position: relative;
      top: 4px;
      margin-right: 6px;
    }
  }
`
export default StepIndicatorMobile
