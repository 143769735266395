import React from "react"
import PropTypes from "prop-types"

import styled from "styled-components"
import { Link } from "../elements"

import tickIcon from "../../images/application/tick-icon.svg"

const Step = ({ stepNumber, stepText, isComplete, isCurrent, url }) => {
  const renderLink = url && isComplete

  return (
    <>
      <StepContainer className={isCurrent ? "current" : null}>
        <div className="step-circle">
          {isComplete && <img src={tickIcon} />}
          {!isComplete && stepNumber}
        </div>
        {renderLink && (
          <span>
            <Link to={url}>{stepText}</Link>
          </span>
        )}
        {!renderLink && (
          <span>
            <p>{stepText}</p>
          </span>
        )}
      </StepContainer>
      {renderLink && (
        <MobileStepContainer>
          <Link to={url}>
            <div className="step-circle">
              {isComplete && <img src={tickIcon} />}
              {!isComplete && stepNumber}
            </div>
          </Link>
        </MobileStepContainer>
      )}
      {!renderLink && (
        <MobileStepContainer>
          <div className="step-circle">
            {isComplete && <img src={tickIcon} />}
            {!isComplete && stepNumber}
          </div>
        </MobileStepContainer>
      )}
    </>
  )
}

const MobileStepContainer = styled.div`
  .step-circle {
    height: 30px;
    width: 30px;
    border: 3px solid ${props => props.theme.color.orange};
    color: ${props => props.theme.color.dark};
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 0.8rem;
    font-family: ${props => props.theme.font.galanoSemi};
    img {
      width: 12px;
    }
  }
  @media (min-width: 768px) {
    display: none;
  }
`
const StepContainer = styled.div`
  display: none;
  align-items: center;
  margin-bottom: 20px;
  opacity: 0.8;
  p,
  a {
    font-size: 0.8rem;
    margin-left: 20px;
    font-family: ${props => props.theme.font.galanoSemi};
  }
  p {
    color: ${props => props.theme.color.grey};
  }
  a {
    color: ${props => props.theme.color.orange};
  }
  .step-circle {
    height: 30px;
    width: 30px;
    border: 3px solid ${props => props.theme.color.orange};
    color: ${props => props.theme.color.dark};
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 0.8rem;
    font-family: ${props => props.theme.font.galanoSemi};
    img {
      width: 12px;
    }
  }
  &.current {
    opacity: 1;
    p {
      color: ${props => props.theme.color.dark};
    }
    .step-circle {
      background: ${props => props.theme.color.orange};
      color: ${props => props.theme.color.white};
    }
  }
  @media (min-width: 788px) {
    display: flex;
  }
`
Step.propTypes = {
  stepNumber: PropTypes.number.isRequired,
  stepText: PropTypes.string.isRequired,
  isComplete: PropTypes.bool.isRequired,
  isCurrent: PropTypes.bool,
  url: PropTypes.string,
}

export default Step
