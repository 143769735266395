/**
 * @flow
 */

import { Field, Formik } from "formik"
import React from "react"
import * as yup from "yup"

import Grid from "@material-ui/core/Grid"

import { validatePhoneNumber } from "../../utils/phonenumber"
import {
  EmailField,
  Form,
  PasswordField,
  SubmitButton,
  TextField,
  Link,
} from "../elements"
import styled from "styled-components"

const validationSchema = yup.object().shape({
  firstName: yup.string().required("Required"),
  lastName: yup.string().required("Required"),
  email: yup
    .string()
    .email("Invalid email address")
    .required("Required"),
  mobileNumber: yup
    .string()
    .required("Required")
    .test("phone-number", "Invalid phone number", value =>
      validatePhoneNumber(value)
    ),
  password: yup.string().required("Required"),
})

type Values = {
  firstName: string,
  lastName: string,
  email: string,
  mobileNumber: string,
  password: string,
}

type Actions = {
  setSubmitting: Function,
}

type Props = {
  onSubmit: (values: Values, actions: Actions) => void | Promise<mixed>,
  initialValues?: Values,
  autoFocusOnFirstField?: Boolean,
}

const SignUpForm = ({
  onSubmit,
  initialValues = {
    firstName: "",
    lastName: "",
    email: "",
    mobileNumber: "",
    password: "",
  },
  autoFocusOnFirstField,
}: Props) => (
  <Formik
    initialValues={initialValues}
    validateOnBlur={false}
    validationSchema={validationSchema}
    onSubmit={onSubmit}
  >
    {({ isSubmitting }) => (
      <Form noValidate>
        <Grid container>
          <Grid item xs={12}>
            <Field
              autoComplete="given-name"
              autoFocus={autoFocusOnFirstField}
              component={TextField}
              id="firstName"
              label="Name"
              name="firstName"
              required
            />
          </Grid>
          <Grid item xs={12}>
            <Field
              autoComplete="family-name"
              component={TextField}
              id="lastName"
              label="Surname"
              name="lastName"
              required
            />
          </Grid>
          <Grid item xs={12}>
            <Field
              autoComplete="email"
              component={EmailField}
              id="email"
              label="Email Address"
              name="email"
              placeholder="someone@example.com"
              required
            />
          </Grid>
          <Grid item xs={12}>
            <Field
              autoComplete="tel"
              component={TextField}
              id="mobileNumber"
              label="Cellphone Number"
              name="mobileNumber"
              required
            />
          </Grid>
          <Grid item xs={12}>
            <Field
              autoComplete="current-password"
              component={PasswordField}
              id="password"
              label="Password"
              name="password"
              required
            />
          </Grid>
        </Grid>
        <SignInLink>
          Already have an account? <Link to="/sign-in">Sign in</Link>
        </SignInLink>
        <SubmitButton
          color="primary"
          variant="contained"
          fullWidth
          loading={isSubmitting}
          disabled={isSubmitting}
          className="submit personal-details"
        >
          NEXT
        </SubmitButton>
      </Form>
    )}
  </Formik>
)

const SignInLink = styled.p`
  color: ${props => props.theme.color.grey};
  font-size: 0.8rem;
  font-family: ${props => props.theme.font.galanoSemi};
  a {
    color: ${props => props.theme.color.dark};
    font-family: ${props => props.theme.font.galanoSemi};
    display: inline-block;
    position: relative;
    margin-top: 5px;
    &:after {
      content: "";
      background: ${props => props.theme.color.dark};
      height: 2px;
      width: 0px;
      position: absolute;
      left: 0;
      bottom: 0;
      transition: 0.25s ease;
    }
    &:hover {
      &:after {
        width: 100%;
      }
    }
  }
`
export default SignUpForm
