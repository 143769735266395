import React, { useMemo, useEffect, useState } from "react"
import { useSelector, useDispatch } from "react-redux"
import { navigate } from "gatsby"
import { Logger } from "@aws-amplify/core"

import { SEO } from "../utils"
import { Button } from "../elements"
import StepIndicator from "./StepIndicator"
import { useVariantRequiresCollection } from "./utils"
import styled from "styled-components"
import { notify } from "../../state"

import { listFiles } from "../../utils/s3"
import paymentImg from "../../images/application/payment-image.svg"
import StepIndicatorMobile from "../elements/StepIndicatorMobile"

const TITLE = "Payment"
const STEP_NUMBER = 5

/**
 *
 */
const PaymentPage = () => {
  const logger = useMemo(() => new Logger(PaymentPage.name), [])
  const dispatch = useDispatch()
  const shopifyRedirectUrl = useSelector(state => state.checkout.webUrl)
  const projectId = useSelector(state => state.shopify.projectId)
  const [isLoading, setLoading] = useState(false)
  const variantRequiresCollection = useVariantRequiresCollection()

  useEffect(() => {
    ;(async () => {
      if (variantRequiresCollection === false) {
        // files are uploaded
        try {
          let imageCount = 0
          const imagePath = `projectId=${projectId}`
          const thumbnailPath = `${imagePath}/thumbs/`
          const rootPath = imagePath.endsWith("/") ? imagePath : `${imagePath}/`

          // list all files in bucket, includes thumbnails
          setLoading(true)
          const files = await listFiles({ path: rootPath })
          const imagePathRegex = new RegExp(`${imagePath}\\/(.*)`)

          for (let file of files) {
            if (file.key.includes(thumbnailPath)) {
              continue
            }

            let match = file.key.match(imagePathRegex)
            if (match != null && match.length > 1) {
              imageCount += 1
            }
          }

          if (imageCount < parseInt(process.env.MIN_PHOTO_UPLOADS)) {
            // We don't have enough photos for an album
            dispatch(
              notify({
                message: `You haven't uploaded enough photos, we need at least ${process.env.MIN_PHOTO_UPLOADS}`,
                variant: "info",
              })
            )
            navigate("app/upload")
          }
        } finally {
          setLoading(false)
        }
      }
    })()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [variantRequiresCollection])

  const handleNext = () => {
    logger.info("Redirecting to shopify")

    window.location = shopifyRedirectUrl
  }

  return (
    <>
      <SEO title={TITLE} />
      <Container>
        <div>
          <StepIndicator
            currentStepNumber={STEP_NUMBER}
            mustCollect={variantRequiresCollection}
          />
        </div>
        <PaymentInstructions>
          <div>
            <h1>Ready to pay?</h1>
            <p>
              You can pay for your photobook using Payfast, <br />
              Zapper or EFT. We take security seriously, that’s <br />
              why if you’re a first time user, you’ll need to sign in <br />
              again on the next page.
            </p>
          </div>
          <Button
            onClick={handleNext}
            className="upload payment"
            disabled={isLoading}
          >
            CONTINUE TO PAYMENT
          </Button>
        </PaymentInstructions>
      </Container>
      <StepIndicatorMobile />
    </>
  )
}

const Container = styled.div`
  @media (min-width: 600px) {
    display: grid;
    grid-template-columns: 34% 50%;
  }
`

const PaymentInstructions = styled.div`
  padding: 50px 5% 50px 5%;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  background-size: 65%;
  background-position: 40% 60%;
  background-repeat: no-repeat;
  h1 {
    font-size: 1.2rem;
    font-family: ${props => props.theme.font.galanoSemi};
    color: ${props => props.theme.color.dark};
    margin-bottom: 30px;
  }
  p {
    font-size: 0.9rem;
    font-family: ${props => props.theme.font.galanoSemi};
    color: ${props => props.theme.color.grey};
  }
  @media (min-width: 768px) {
    background-image: url(${paymentImg});
    min-height: 100vh;
    padding: 100px 5% 50px 5%;
    h1 {
      margin: 0;
    }
    p {
      transform: translateY(30px);
    }
  }
  @media (min-width: 1024px) {
  }
`

export default PaymentPage
