/**
 * @flow
 */
import _ from "lodash"
import Auth from "@aws-amplify/auth"
import { Logger } from "@aws-amplify/core"
import { navigate } from "gatsby"
import React, { useEffect, useMemo, useState } from "react"
import { useDispatch } from "react-redux"

import { Container } from "@material-ui/core"

import { notify, setUser } from "../../state"
import SignIn from "./SignIn"
import { useCustomerDetails } from "./utils"

const SignInPage = () => {
  const [loading, setLoading] = useState(true)
  const [federatedIdentity, setFederatedIdentity] = useState(null)
  const [customer, isCustomerLoading] = useCustomerDetails()
  const logger = useMemo(() => new Logger(SignInPage.name), [])
  const dispatch = useDispatch()

  useEffect(() => {
    ;(async () => {
      try {
        const identity = await Auth.currentAuthenticatedUser()
        setFederatedIdentity(identity)
        logger.info("User already logged in, federated identity: ", identity)
        // eslint-disable-next-line no-empty
      } catch {}
    })()
  }, [logger])

  useEffect(() => {
    ;(async () => {
      try {
        if (!isCustomerLoading && !_.isEmpty(customer) && federatedIdentity) {
          dispatch(setUser(customer))
          navigate("/app", { replace: true })
          dispatch(
            notify({
              message: `Welcome back ${customer.displayName}.`,
              variant: "info",
            })
          )
        }
      } catch (err) {
        // No authenticated user
      } finally {
        setLoading(false)
      }
    })()
  }, [logger, dispatch, customer, isCustomerLoading, federatedIdentity])

  if (loading) return null

  const handleSignUp = () => {
    navigate("sign-up")
  }

  return (
    <Container component="main" maxWidth="xs">
      <SignIn onSignUp={handleSignUp} autoFocusOnFirstField={true} />
    </Container>
  )
}

export default SignInPage
