import PropTypes from "prop-types"
import React from "react"
import { useSelector } from "react-redux"

import AppContainer from "./AppContainer"
import { SEO } from "./utils"
import { Gallery, Button } from "./elements"

const TITLE = "Test"

const TestPage = () => {
  const projectId = useSelector(state => state.shopify.projectId)

  const handleCheckoutClick = () => {
    // TODO: must update checkout and continue to payment
  }

  return (
    <>
      <SEO title={TITLE} />
      <AppContainer title={TITLE}>
        {projectId != null && <Gallery projectId={projectId}></Gallery>}
        <Button onClick={handleCheckoutClick}>Checkout</Button>
      </AppContainer>
    </>
  )
}

TestPage.propTypes = {
  user: PropTypes.object.isRequired,
}

export default TestPage
