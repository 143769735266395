/**
 * @flow
 */

import { Field, Formik } from "formik"
import React from "react"
import * as yup from "yup"

import { EmailField, Form, SubmitButton } from "../elements"

const validationSchema = yup.object().shape({
  email: yup
    .string()
    .email("Invalid email")
    .required("Required"),
})

type Values = {
  email: string,
}

type Actions = {
  setSubmitting: Function,
}

type Props = {
  onSubmit: (values: Values, actions: Actions) => void | Promise<mixed>,
  initialValues?: Values,
}

const ForgotPasswordForm = ({
  onSubmit,
  initialValues = {
    email: "",
  },
}: Props) => {
  return (
    <Formik
      initialValues={initialValues}
      validateOnBlur={false}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {({ isSubmitting }) => (
        <Form noValidate>
          <Field
            autoFocus
            component={EmailField}
            id="email"
            label="Email Address"
            name="email"
            required
          />
          <SubmitButton
            color="primary"
            variant="contained"
            fullWidth
            loading={isSubmitting}
            disabled={isSubmitting}
            className="sign-in"
          >
            Send
          </SubmitButton>
        </Form>
      )}
    </Formik>
  )
}

export default ForgotPasswordForm
