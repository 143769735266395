/**
 * @flow
 */
import _ from "lodash"
import { useCallback } from "react"
import { useSelector } from "react-redux"
import { useQuery, useMutation } from "@apollo/react-hooks"

import {
  CUSTOMER_ORDERS_FETCH,
  CUSTOMER_LAST_INCOMPLETE_CHECKOUT,
  GET_CHECKOUT_QUERY,
} from "./queries"

import {
  SHIPPING_ADDRESS_UPDATE,
  CHECKOUT_ATTRIBUTES_UPDATE,
} from "./mutations"

import { useCustomerAccessToken } from "../auth/utils"

const ADDRESS_FIELDS = [
  "address1",
  "address2",
  "province",
  "city",
  "zip",
  "country",
]

type Address = {
  address1: string,
  address2: string,
  province: string,
  city: string,
  zip: string,
  country: string,
}

type CustomAttribute = {
  key: string,
  value: string,
}

export const useCustomerOrders = () => {
  const [accessToken, accessTokenLoading] = useCustomerAccessToken()
  const queryResult = useQuery(CUSTOMER_ORDERS_FETCH, {
    variables: {
      customerAccessToken: accessToken,
    },
    skip: accessTokenLoading || _.isNull(accessToken),
  })

  return [
    _.get(queryResult, "data.customer.orders.edges"),
    accessTokenLoading || _.get(queryResult, "loading"),
    _.get(queryResult, "error"),
  ]
}

export const useCustomerLastIncompleteCheckout = () => {
  const [accessToken, accessTokenLoading] = useCustomerAccessToken()
  const queryResult = useQuery(CUSTOMER_LAST_INCOMPLETE_CHECKOUT, {
    variables: {
      customerAccessToken: accessToken,
    },
    skip: accessTokenLoading || _.isNull(accessToken),
  })

  return [
    _.get(queryResult, "data.customer.lastIncompleteCheckout"),
    accessTokenLoading || _.get(queryResult, "loading"),
    _.get(queryResult, "error"),
  ]
}

export const useVariantRequiresCollection = () => {
  const selectedVariant = useSelector(state => state.shopify.selectedVariant)

  if (selectedVariant && selectedVariant.selectedOptions) {
    for (let option of selectedVariant.selectedOptions) {
      if (option.name == "Upload-or-Collection") {
        return option.value == "Collection"
      }
    }
  }
  return false
}

export const useShippingAddressUpdate = (checkoutId: string) => {
  const [updateShippingAddress] = useMutation(SHIPPING_ADDRESS_UPDATE)

  const update = useCallback(
    async (shippingAddress: Address) => {
      const result = await updateShippingAddress({
        variables: {
          checkoutId,
          shippingAddress,
        },
      })
      const data = result.data.checkoutShippingAddressUpdateV2
      if (!_.isEmpty(data.checkoutUserErrors)) {
        throw new Error(data.checkoutUserErrors.message)
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [checkoutId]
  )

  return update
}

export const useCollectionAddressUpdate = (checkoutId: string) => {
  const [updateCheckoutAttributes] = useMutation(CHECKOUT_ATTRIBUTES_UPDATE)

  const update = useCallback(
    async (
      collectionAddress: Address,
      bookTitle: string,
      isTitleOnSpine: boolean
    ) => {
      const result = await updateCheckoutAttributes({
        variables: {
          checkoutId,
          input: {
            customAttributes: [
              {
                key: "collectionAddress",
                value: convertAddressToString(collectionAddress),
              },
              {
                key: "bookTitle",
                value: bookTitle,
              },
              {
                key: "isTitleOnSpine",
                value: String(isTitleOnSpine),
              },
            ],
          },
        },
      })
      const data = result.data.checkoutAttributesUpdateV2
      if (!_.isEmpty(data.checkoutUserErrors)) {
        throw new Error(data.checkoutUserErrors.message)
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [checkoutId]
  )

  return update
}

export const useCustomAttrUpdate = (checkoutId: string) => {
  const [updateCheckoutAttributes] = useMutation(CHECKOUT_ATTRIBUTES_UPDATE)

  const update = useCallback(
    async (projectId: string, bookTitle: string, eventType: string) => {
      const result = await updateCheckoutAttributes({
        variables: {
          checkoutId,
          input: {
            customAttributes: [
              {
                key: "_project",
                value: `{"id": "${projectId}"}`,
              },
              {
                key: "bookTitle",
                value: bookTitle,
              },
              {
                key: "eventType",
                value: eventType,
              },
            ],
          },
        },
      })
      const data = result.data.checkoutAttributesUpdateV2
      if (!_.isEmpty(data.checkoutUserErrors)) {
        throw new Error(data.checkoutUserErrors.message)
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [checkoutId]
  )

  return update
}

export const convertAddressToString = (address: Address) => {
  let addressString = ""
  ADDRESS_FIELDS.forEach(function(key) {
    if (address[key] != undefined) {
      addressString = addressString + address[key] + " | "
    } else {
      addressString = addressString + " | "
    }
  })
  return addressString
}

export const convertStringToAddress = (addressString: string): Address => {
  const addressFragments = addressString.split("|")
  const address: Address = {}
  ADDRESS_FIELDS.forEach((key, index) => {
    address[key] = addressFragments[index].trim()
  })
  return address
}

export const getCustomAttribute = (
  customAttributes: [CustomAttribute],
  key: string
): ?CustomAttribute => {
  for (let attribute of customAttributes) {
    if (attribute.key == key) {
      return attribute
    }
  }
  return null
}

export const useCustomerCheckout = (checkoutId: string) => {
  const queryResult = useQuery(GET_CHECKOUT_QUERY, {
    variables: {
      id: checkoutId,
    },
  })

  return [
    _.get(queryResult, "data.node"),
    _.get(queryResult, "loading"),
    _.get(queryResult, "error"),
  ]
}
