import React from "react"

import Container from "@material-ui/core/Container"
import Typography from "@material-ui/core/Typography"

import { SEO } from "./utils"

const NotFoundPage = () => (
  <Container component="main">
    <SEO title="Not Found" />
    <Typography component="h1" variant="h6">
      Not Found
    </Typography>
    <Typography variant="body1">
      You just hit a route that doesn&#39;t exist... the sadness.
    </Typography>
  </Container>
)

export default NotFoundPage
