import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import _ from "lodash"
import styled from "styled-components"
import { SubmitButton } from "../elements"
import Options from "./Options"
import ProductDetails from "../home/Product/ProductDetails"
import ProductSwiper from "../home/Product/ProductSwiper"
import ProductSelectButton from "../elements/ProductSelectButton"

const Product = ({ product, onSelect, changeProduct, allProducts }) => {
  const [variants, setVariants] = useState(null)
  const [options, setOptions] = useState({})
  const [selectedOptions, setSelectedOptions] = useState({})
  const [selectedVariant, setSelectedVariant] = useState(null)
  const [price, setPrice] = useState(null)
  const optionHeadings = {
    Cover: "What cover would you like?",
    "Upload-or-Collection": "How will you submit your photos?",
  }
  const optionTooltips = {
    Colours: "Select the colour of your photo book from the options below.",
    "Upload-or-Collection": "Choose to upload or for us to collect.",
    Size: "Please select your desired size from the options below.",
  }

  useEffect(() => {
    const variants = []
    const options = {}

    if (product) {
      for (let variant of product.variants.edges) {
        variants.push(variant.node)

        for (let option of variant.node.selectedOptions) {
          if (_.isEmpty(options[option.name])) {
            options[option.name] = new Set([option.value])
          } else {
            options[option.name].add(option.value)
          }
        }
      }
    }
    setVariants(variants)
    setOptions(options)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (variants) {
      let matchedVariant = false
      for (let variant of variants) {
        const variantOptions = {}
        for (let option of variant.selectedOptions) {
          variantOptions[option.name] = option.value
        }
        if (_.isEqual(selectedOptions, variantOptions)) {
          setSelectedVariant(variant)
          matchedVariant = true
          setPrice(variant.price)
          break
        }
      }
      if (!matchedVariant) {
        setSelectedVariant(null)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedOptions])

  const handleProductSelect = (e, productId) => {
    if (onSelect) {
      onSelect(productId, selectedVariant)
    }
    e.preventDefault()
  }

  const handleOptionSelect = (option, value) => {
    setSelectedOptions(selectedOptions => {
      return { ...selectedOptions, [option]: value }
    })
  }
  const handleProductChange = i => {
    changeProduct(i)
  }
  return (
    <>
      <Header id="product">
        <h1>Get Started</h1>
        <p>Please choose your photo book type.</p>
        <ProductSelectButton
          products={allProducts}
          handleProductChange={handleProductChange}
          active={product}
        />
      </Header>
      <Container>
        <ProductSwiper
          product={product.images.edges}
          selectedVariant={selectedVariant}
        />
        <InfoContainer>
          <ProductDetails
            title={product.title}
            price={price}
            descriptionHtml={product.descriptionHtml}
          />
          <Underline />
          {options && (
            <Options
              options={options}
              headings={optionHeadings}
              tooltips={optionTooltips}
              onSelect={handleOptionSelect}
            />
          )}
          <SubmitButton
            className="product"
            variant="contained"
            fullWidth
            disabled={selectedVariant == null}
            onClick={e => handleProductSelect(e, product.id)}
          >
            Continue
          </SubmitButton>
        </InfoContainer>
      </Container>
    </>
  )
}
const Header = styled.div`
  text-align: center;
  padding: 50px 5%;
  h1 {
    font-size: 1.5rem;
    font-family: ${props => props.theme.font.galanoBold};
    color: ${props => props.theme.color.grey};
  }
  p {
    font-family: ${props => props.theme.font.galanoMed};
    font-size: 0.8rem;
  }
  @media (min-width: 768px) {
    padding-top: 100px;
    padding-bottom: 0;
    h1 {
      font-size: 2.5rem;
    }
    p {
      font-size: 1.2rem;
    }
  }
`

const Container = styled.div`
  padding: 50px 15px;
  display: grid;
  @media (min-width: 768px) {
    grid-template-columns: 50% 45%;
    padding: 150px 30px 100px 30px;
    grid-gap: 50px;
  }
  @media (min-width: 1024px) {
    grid-template-columns: 50% 35%;
    padding: 200px 30px 100px 30px;
  }
`
const Underline = styled.div`
  opacity: 0.3;
  display: block;
  margin: 20px auto;
  background: ${props => props.theme.color.lightGrey};
  height: 1px;
  @media (min-width: 1024px) {
    width: 330px;
    margin: 20px 0;
  }
`
const InfoContainer = styled.div`
  .video {
    margin-top: 50px;
    display: flex;
    align-items: baseline;
    p,
    a {
      font-size: 0.7rem;
      font-family: ${props => props.theme.font.galanoBold};
      color: ${props => props.theme.color.grey};
      text-transform: uppercase;
    }
    div {
      padding: 0 5px 0 5px;
    }
    a {
      transition: 0.25s ease;
      display: block;
      .underline {
        height: 2px;
        width: 30%;
        background: ${props => props.theme.color.grey};
        transition: 0.25s ease;
      }
      &:hover {
        opacity: 0.6;
        padding-right: 5px;
        .underline {
          width: 120%;
        }
      }
    }
    img {
      max-width: 15px;
    }
  }
`

Product.propTypes = {
  product: PropTypes.object.isRequired,
  onSelect: PropTypes.func,
  changeProduct: PropTypes.func,
  allProducts: PropTypes.array,
}

export default Product
