import PropTypes from "prop-types"
import React from "react"
import { connect } from "react-redux"

import AppContainer from "./AppContainer"
import { SEO } from "./utils"

const TITLE = "Dashboard"

const DashboardPage = ({ user }) => {
  return (
    <>
      <SEO title={TITLE} />
      <AppContainer title={TITLE}>{user.firstName}</AppContainer>
    </>
  )
}

DashboardPage.propTypes = {
  user: PropTypes.object.isRequired,
}

const mapStateToProps = state => ({
  user: state.auth.user,
})

export default connect(mapStateToProps)(DashboardPage)
