import React from "react"
import styled from "styled-components"
import PropTypes from "prop-types"

import uploadYellow from "../../images/landing/upload-icon.svg"
import uploadDark from "../../images/landing/upload-icon-dark-grey.svg"

import collectYellow from "../../images/landing/collect-icon-yellow.svg"
import collectDark from "../../images/landing/collect-icon-dark.svg"

const ChipIcon = props => {
  if (props.value == "Upload") {
    return (
      <>
        {props.selected ? (
          <Icon src={uploadYellow} />
        ) : (
          <Icon src={uploadDark} />
        )}
      </>
    )
  } else if (props.value == "Collection") {
    return (
      <>
        {props.selected ? (
          <Icon src={collectYellow} />
        ) : (
          <Icon src={collectDark} />
        )}
      </>
    )
  }
  return null
}

const Icon = styled.img`
  max-width: 20px;
`
ChipIcon.propTypes = {
  value: PropTypes.string,
  selected: PropTypes.bool,
}

export default ChipIcon
