/**
 * @flow
 */
import _ from "lodash"
import Auth from "@aws-amplify/auth"
import { Logger } from "@aws-amplify/core"
import CircularProgress from "@material-ui/core/CircularProgress"
import { navigate } from "gatsby"
import React, { useEffect, useMemo, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Router } from "@reach/router"
import styled from "styled-components"

import {
  useSignOut,
  useCustomerDetails,
  doAWSSignIn,
  useRenewShopifyCustomerAccessToken,
} from "./auth/utils"

import {
  useCustomerOrders,
  useCustomerLastIncompleteCheckout,
  convertStringToAddress,
  getCustomAttribute,
  useCustomerCheckout,
} from "./checkout/utils"

import { TestPage, NotFoundPage, PrivateRoute } from "../components"

import {
  OrdersPage,
  DeliveryPage,
  CollectionPage,
  UploadPage,
  PaymentPage,
} from "./checkout"

import {
  setUser,
  notify,
  setCustomerOrders,
  setSelectedVariant,
  setCheckoutId,
  setCheckoutWebUrl,
  setDeliveryAddress,
  setCollectionAddress,
  setBookAttributes,
  setProjectId,
  clearCheckout,
} from "../state"

const App = () => {
  const [loading, setLoading] = useState(true)
  const [federatedIdentity, setFederatedIdentity] = useState(null)
  const [error, setError] = useState(null)
  const [customer, isCustomerLoading, customerLoadError] = useCustomerDetails()
  const [
    customerOrders,
    isCustomerOrdersLoading,
    customerOrdersLoadError,
  ] = useCustomerOrders()
  const [
    customerCheckout,
    isCustomerCheckoutLoading,
    customerCheckoutLoadError,
  ] = useCustomerLastIncompleteCheckout()
  const renewCustomerAccessToken = useRenewShopifyCustomerAccessToken()
  const signOut = useSignOut()
  const dispatch = useDispatch()
  const checkoutId = useSelector(state => state.checkout.id)
  const logger = useMemo(() => new Logger(App.name), [])

  const [currentCheckout, isCurrentCheckoutLoading] = useCustomerCheckout(
    checkoutId
  )

  useEffect(() => {
    ;(async () => {
      try {
        const { accessToken } = await renewCustomerAccessToken()
        await doAWSSignIn(accessToken)
        const identity = await Auth.currentAuthenticatedUser()
        setFederatedIdentity(identity)
      } catch (error) {
        setError("Failed to authenticate, please log in again")
        logger.warn(error)
      }
    })()
  }, [logger, renewCustomerAccessToken])

  useEffect(() => {
    try {
      if (
        checkoutId &&
        !isCurrentCheckoutLoading &&
        !_.isEmpty(currentCheckout)
      ) {
        if (currentCheckout.completedAt !== null) {
          dispatch(clearCheckout())
        }
      }

      if (!_.isEmpty(error)) {
        throw new Error(error)
      }

      if (!isCustomerLoading && !_.isEmpty(customerLoadError)) {
        throw new Error(customerLoadError)
      }

      if (!isCustomerOrdersLoading && !_.isEmpty(customerOrdersLoadError)) {
        throw new Error(customerOrdersLoadError)
      }

      if (!isCustomerCheckoutLoading && !_.isEmpty(customerCheckoutLoadError)) {
        throw new Error(customerCheckoutLoadError)
      }

      if (_.isEmpty(federatedIdentity)) {
        return
      }

      if (!isCustomerLoading && !_.isEmpty(customer)) {
        dispatch(setUser(customer))
      }

      if (!isCustomerOrdersLoading && !_.isEmpty(customerOrders)) {
        dispatch(setCustomerOrders(customerOrders))
      }

      if (!isCustomerCheckoutLoading && !_.isEmpty(customerCheckout)) {
        if (checkoutId && customerCheckout.id != checkoutId) {
          dispatch(setCheckoutId(null))
          dispatch(setCheckoutWebUrl(null))
          throw new Error("Last checkout id doesn't match one in store")
        }
        dispatch(setCheckoutId(customerCheckout.id))
        dispatch(setCheckoutWebUrl(customerCheckout.webUrl))
        dispatch(
          setSelectedVariant(
            _.get(customerCheckout, "lineItems.edges.0.node.variant")
          )
        )
        dispatch(
          setDeliveryAddress({
            address1: _.get(customerCheckout, "shippingAddress.address1"),
            address2: _.get(customerCheckout, "shippingAddress.address2"),
            city: _.get(customerCheckout, "shippingAddress.city"),
            province: _.get(customerCheckout, "shippingAddress.provinceCode"),
            zip: _.get(customerCheckout, "shippingAddress.zip"),
            country: _.get(customerCheckout, "shippingAddress.countryCodeV2"),
          })
        )
        const collectionAddress = getCustomAttribute(
          customerCheckout.customAttributes,
          "collectionAddress"
        )
        if (collectionAddress) {
          dispatch(
            setCollectionAddress(
              convertStringToAddress(collectionAddress.value)
            )
          )
        }

        let bookTitle = getCustomAttribute(
          customerCheckout.customAttributes,
          "bookTitle"
        )
        let isTitleOnSpine = getCustomAttribute(
          customerCheckout.customAttributes,
          "isTitleOnSpine"
        )
        bookTitle = bookTitle ? bookTitle.value : null
        isTitleOnSpine = isTitleOnSpine ? Boolean(isTitleOnSpine.value) : null
        dispatch(setBookAttributes({ bookTitle, isTitleOnSpine }))

        let project = getCustomAttribute(
          customerCheckout.customAttributes,
          "_project"
        )
        if (project) {
          project = JSON.parse(project.value)
          dispatch(setProjectId(project.id))
        }
      }

      // If there is not previous incomplete checkout ID and no checkout has been created, redirect to home page
      if (loading && !isCustomerCheckoutLoading && !checkoutId) {
        navigate("/", { replace: true })
      }

      // If nothing is still loading, set loading to false
      if (
        !isCustomerOrdersLoading &&
        !isCustomerLoading &&
        !isCustomerCheckoutLoading
      ) {
        setLoading(false)
      }
    } catch (e) {
      signOut()
      dispatch(setUser())
      dispatch(
        notify({
          message: e.message,
          variant: "error",
        })
      )
      navigate("/sign-in", { replace: true })
    }
  }, [
    dispatch,
    error,
    customer,
    checkoutId,
    customerLoadError,
    isCustomerLoading,
    federatedIdentity,
    signOut,
    isCustomerOrdersLoading,
    customerOrders,
    customerOrdersLoadError,
    customerCheckout,
    isCustomerCheckoutLoading,
    customerCheckoutLoadError,
    loading,
    isCurrentCheckoutLoading,
    currentCheckout,
  ])

  if (loading) {
    return (
      <LoadingContainer>
        <CircularProgress />
      </LoadingContainer>
    )
  }

  return (
    <Router>
      <PrivateRoute path="app" component={DeliveryPage} />
      <PrivateRoute path="app/orders" component={OrdersPage} />
      <PrivateRoute path="app/delivery" component={DeliveryPage} />
      <PrivateRoute path="app/collection" component={CollectionPage} />
      <PrivateRoute path="app/upload" component={UploadPage} />
      <PrivateRoute path="app/payment" component={PaymentPage} />
      <PrivateRoute path="app/test" component={TestPage} />
      <NotFoundPage default />
    </Router>
  )
}

const LoadingContainer = styled.div`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  .MuiCircularProgress-colorPrimary {
    color: ${props => props.theme.color.dark};
  }
`
export default App
